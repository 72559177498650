// External Imports
import React, { Fragment } from "react";
import { graphql, navigate } from "gatsby";
// Components
import {
  DocsCallToAction,
  PanelWrapper,
  Breadcrumb,
  useGuideSidebar,
  SEO,
} from "../../../exports/components.exports";
import { Pagination, Grid, Box, Container, Divider } from "@mui/material";
// Config
import { urlRoutes } from "../../../exports/constants.exports";
import AllGuides from "./AllGuides";
import { colors } from "../../../exports/assets.exports";

export const query = graphql`
  query GuideCategory($slug: String!, $skip: Int!, $limit: Int!) {
    guides: allStrapiGuide(
      sort: { fields: updated_at, order: DESC }
      filter: { Category: { slug: { eq: $slug } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          Title
          Description
          Content
          FeatureImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          Slug
          updated_at(fromNow: true)
          Author {
            name
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          Category {
            name
            slug
          }
          SimilarGuides {
            Slug
            Title
          }
        }
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
      slug
    }
    allStrapiCategory {
      edges {
        node {
          slug
          name
        }
      }
    }
    allGuides: allStrapiGuide {
      edges {
        node {
          Title
          Slug
          Category {
            name
          }
        }
      }
    }
  }
`;

/**
 * @function GuidesCategoryPage
 * @param data from the graph ql query
 * @returns the guides category page
 */
const GuidesCategoryPage = ({
  data,
  pageContext,
}: {
  data: any;
  pageContext: any;
}) => {
  const guides = data.guides.edges;
  const { name: categoryName, slug: categorySlug } = data.category;
  const { currentPage, numPages } = pageContext;

  const DrawerSideBar = useGuideSidebar(data);

  const seo = {
    metaTitle: `Swiftcase - ${categoryName} Category Guides  - Page ${currentPage}`,
    metaDescription: `A paginated list of all of Swiftcase's ${categoryName}  category guides from over the last few years.`,
  };

  /**
   * @function handlePaginationChange
   * @param value the page number to navigate to
   * @returns the navigation from gatsby
   */
  const handlePaginationChange = (_: any, value: number) => {
    if (value === 1) {
      navigate(`${urlRoutes.GUIDES}/${categorySlug}/`);
    } else {
      navigate(`${urlRoutes.GUIDES}/${categorySlug}/${value}/`);
    }
  };

  return (
    <Fragment>
      <SEO seo={seo} />
      <DocsCallToAction />
      <Box sx={{ backgroundColor: colors.swiftcaseWebsiteLightGrey }}>
        <Container
          maxWidth="lg"
          sx={{ backgroundColor: colors.swiftcaseWebsiteLightGrey }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginY: "2rem",
            }}
          >
            <Breadcrumb
              primary={{ text: "Guides", href: urlRoutes.GUIDES }}
              currentPage={categoryName}
            />
            <Box>{DrawerSideBar}</Box>
          </Box>
        </Container>
        <Divider />
        <PanelWrapper
          contentWidth="lg"
          minHeight="25vh"
          backgroundColor={colors.swiftcaseWebsiteLightGrey}
          centered
        >
          <Grid
            container
            className="guide-card-grid"
            spacing={4}
            sx={{
              justifyContent: guides.length !== 0 ? "flex-start" : "center",
            }}
          >
            <AllGuides guides={guides} category={categoryName} />

            <Grid
              item
              className="guide-grid__grid-item"
              xs={12}
              sx={{ marginTop: "2rem" }}
            >
              <Pagination
                showFirstButton
                showLastButton
                count={numPages}
                page={currentPage}
                boundaryCount={2}
                onChange={handlePaginationChange}
                className="guide-grid__pagination"
                sx={{
                  width: "fit-content",
                  marginX: "auto",
                }}
              />
            </Grid>
          </Grid>
        </PanelWrapper>
      </Box>
    </Fragment>
  );
};

export default GuidesCategoryPage;
