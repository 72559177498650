// External Imports
import React, { Fragment } from "react";
// Components
import { GuideCard, SkeletonCard } from "../../../exports/components.exports";
import { Grid } from "@mui/material";
// Config
import { urlRoutes } from "../../../exports/constants.exports";

/**
 * @function AllGuides
 * @returns the guides grid item where a skeleton is returned if no articles, and otherwise it renders a grid of article cards
 */
const AllGuides = ({ guides, category }: { guides: any; category?: any }) => {
  /**
   * @function renderGuideCardGrid
   * @returns the grid item and guide card for the guide
   */
  const renderGuideCardGrid = (guidesData: any) => {
    return guidesData.map((guide: any) => {
      return (
        <Grid
          item
          lg={4}
          md={6}
          xs={12}
          className="guide-card-grid__item"
          key={`guide__${guide.node.Slug}`}
        >
          <GuideCard guide={guide} />
        </Grid>
      );
    });
  };

  return (
    <Fragment>
      {guides.length < 1 && (
        <Grid item xs={12} md={6} className="guide-category__no-blogs">
          <SkeletonCard
            title="guide"
            category={category ?? "Guides"}
            link={urlRoutes.GUIDES}
          />
        </Grid>
      )}
      {guides.length >= 1 && (
        <Grid item xs={12} className="guide-category__grid-item">
          <Grid
            container
            className="guide-card-grid"
            spacing={4}
            justifyContent="space-flex-start"
          >
            {renderGuideCardGrid(guides)}
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default AllGuides;
